import { Accordion, ListGroup } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
import {
  Item,
  LeftWrapper,
  Dot,
  TextWrapper,
  Title,
  RightWrapper,
  NumberWrapper,
  TopWrapper,
  TitleWrapper,
  ListTitle,
} from "../../../styles/list";
// import ChevronRight from "../../assets/images/right-chevron.png";
import { User, formatNumbers } from "../../../utils";
import { useTheme } from "styled-components";
import { AccordionArrow } from "../../../components/AccorditionArrow";
import { useMemo } from "react";
import AddButton from "../../../components/AddButton";

interface Props {
  homepage?: boolean;
  currentBalance: number;
  openDeposit: () => void;
}

const DashboardAccount = ({ homepage, currentBalance, openDeposit }: Props) => {
  const theme = useTheme();
  const isDefaultActive = useMemo(
    () =>
      User.getUsersPreferencesFromLocalStorage()?.dashboardCashAccountCollapsed,
    []
  );
  // const navigate = useNavigate();
  // const handleClick = () => {
  //   navigate("/flex-account");
  // };
  return (
    <Accordion defaultActiveKey={!!isDefaultActive ? undefined : "0"}>
      <TopWrapper>
        <TitleWrapper>
          <ListTitle>Cash</ListTitle>
          {/* <AddButton handleClick={openDeposit} /> */}
          <AccordionArrow
            eventKey="0"
            preferenceKey="dashboardCashAccountCollapsed"
          />
        </TitleWrapper>
      </TopWrapper>
      <Accordion.Collapse eventKey="0">
        <ListGroup>
          <Item style={{ cursor: "default", height: "72px" }}>
            <LeftWrapper>
              <div>
                <Dot color={theme.GREEN} displaydot={homepage ? "true" : ""} />
              </div>
              <TextWrapper>
                <Title>Buying Power</Title>
              </TextWrapper>
            </LeftWrapper>
            <RightWrapper>
              <NumberWrapper>
                <Title>${formatNumbers(currentBalance)}</Title>
              </NumberWrapper>
              {/* <img
            src={ChevronRight}
            width={25}
            height={25}
            alt=""
            className="object-fit-cover"
          /> */}
            </RightWrapper>
          </Item>
        </ListGroup>
      </Accordion.Collapse>
    </Accordion>
  );
};

export default DashboardAccount;
