import { LinkDestination } from "../types";
import { useBankAccounts } from "./useBankAccounts";
import { useBeneficiaries } from "./useBeneficiaries";
import { useFinancialData } from "./useFinancialData";
import { usePlaidBankAccount } from "./usePlaidBankAccount";
import { useUserData } from "./useUserData";
import { useUserPreferences } from "./useUserPreferences";
import { useUserSettings } from "./useUserSettings";

export const useUserAccount = () => {
  const {
    loading: userDataLoading,
    userInitialData,
    updateUserData,
  } = useUserData();
  const {
    loading: bankAccountsLoading,
    bankAccounts,
    handleDelete,
  } = useBankAccounts();
  const { loading: userSettingsLoading, data: userSettings } =
    useUserSettings();

  const { open, ready, waitingBankAccounts } = usePlaidBankAccount(
    undefined,
    undefined,
    LinkDestination.UserAccount
  );
  const {
    initialData: financialInitialData,
    loading: financialLoading,
    updateNetAssets,
  } = useFinancialData();
  const { data: userPreferences, loading: loadingUserPreferences } =
    useUserPreferences();

  const {
    beneficiaries,
    loading: loadingBeneficiaries,
    deleteBeneficiary,
    createBeneficiary,
  } = useBeneficiaries();

  return {
    open,
    ready,
    userDataLoading,
    bankAccountsLoading,
    userSettingsLoading,
    userSettings,
    userInitialData,
    bankAccounts,
    waitingBankAccounts,
    handleDelete,
    updateUserData,
    financialInitialData,
    financialLoading,
    updateNetAssets,
    userPreferences,
    loadingUserPreferences,
    beneficiaries,
    loadingBeneficiaries,
    createBeneficiary,
    deleteBeneficiary,
  };
};
