import { FormEvent } from "react";
import styled from "styled-components";
import { FormButton } from "../styles";
import { Loading } from "./Loading";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { ReactComponent as QuestionMark } from "../assets/icons/question-mark.svg";
import { AccordionArrow } from "./AccorditionArrow";
import { UserPreferences } from "../types/users";
import { Accordion } from "react-bootstrap";
import AddButton from "./AddButton";

interface Props {
  children: React.ReactNode;
  onSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
  loading: boolean;
  buttonMessage: string;
  title: string;
  needQuestionMark?: boolean;
  questionMarkMessage?: string;
  fetchingData?: boolean;
  needAddButton?: boolean;
  removeAddButton?: boolean;
  collapseKey?: keyof UserPreferences;
  isActive?: boolean;
}

const FormWrapper = ({
  children,
  onSubmit,
  loading,
  buttonMessage,
  title,
  needQuestionMark,
  questionMarkMessage,
  fetchingData,
  needAddButton,
  removeAddButton = false,
  collapseKey,
  isActive,
}: Props) => {
  const renderTooltip = (props: any) => (
    <HoverBox id="button-tooltip" {...props}>
      {questionMarkMessage}
    </HoverBox>
  );

  return (
    <Accordion defaultActiveKey={!!isActive ? undefined : "0"}>
      <Form className="border rounded mt-3 shadow-none" onSubmit={onSubmit}>
        <div className="d-flex justify-content-center flex-column w-100 align-items-center px-2 mt-2 pb-2">
          <div className="mt-0 d-flex align-items-center flex-column g-3 w-100">
            <div className="row g-3 w-100">
              <div className="col-md-12 d-flex align-items-center gap-2">
                <h4 style={{ padding: "10px 0px" }}>{title}</h4>
                {fetchingData && (
                  <Loading withoutMargin width="28px" height="28px" />
                )}
                <div className="d-flex align-items-center gap-2">
                  {needQuestionMark && (
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltip}
                    >
                      <QuestionMarkButton />
                    </OverlayTrigger>
                  )}
                  {!removeAddButton && needAddButton && (
                    <AddButton disabled={loading} />
                  )}
                  {collapseKey && (
                    <div className="pb-1">
                      <AccordionArrow
                        eventKey="0"
                        preferenceKey={collapseKey}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Accordion.Collapse eventKey="0" className="w-100 mb-2">
              <>
                {children}
                {!removeAddButton && !needAddButton && (
                  <ButtonWrapper className="mt-3">
                    <FormButton type="submit" disabled={loading}>
                      {buttonMessage}
                    </FormButton>
                  </ButtonWrapper>
                )}
              </>
            </Accordion.Collapse>
          </div>
        </div>
      </Form>
    </Accordion>
  );
};

const QuestionMarkButton = styled(QuestionMark)`
  height: 25px;
  width: 25px;
  cursor: pointer;
  margin-bottom: 5px;
  path {
    stroke: ${({ theme }) => theme.PRIMARY_COLOR} !important;
  }
  @media (max-width: 400px) {
    width: 28px;
  }
`;

const Form = styled.form`
  border-color: ${({ theme }) => theme.LIST_BORDER_COLOR} !important;
`;

const HoverBox = styled(Tooltip)`
  padding: 5px;
  border-radius: 5px;
  font-size: 10px;
  .tooltip-inner {
    color: black !important;
    background-color: #ebebeb !important;
  }
  --bs-tooltip-bg: #ebebeb !important;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px 16px;
  padding-right: 16px;
  @media (max-width: 380px) {
    margin-top: 0px !important;
    padding: 10px 16px;
  }
`;

export default FormWrapper;
