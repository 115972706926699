import { useState } from "react";
import { UserData } from "../types/users";

/**
 * ClientUserData is the full user object saved to the database.
 * ClientUserData should only be used by admins. Users can access everything they need about themselves in UserData.
 */
export const useClientUserData = () => {
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [users, setUsers] = useState<ClientUserData[]>();
  const [error, setError] = useState<string>();
  /**
   * Get all users. There is no paging. All the users are returned in one request. (Eventually, paging will have to be added.)
   */
  const getUsers = async () => {
    setLoading(true);
    const path = `/client-user-data`;
    try {
      const response = await fetch(path, {
        method: "GET",
      });
      const res: ClientUserDataResponse = await response.json();
      if (response.ok) {
        setUsers(res.clientUserData);
        setError(undefined);
      } else {
        console.log(`Error: ${response.statusText}`);
        setError(`Error getting ClientUserData`);
      }
    } catch (e) {
      console.log(`Error: ${e}`);
      setError(`Error getting ClientUserData`);
    }
    setLoaded(true);
  };
  /**
   * Update one or more properties of a ClientUserData object.
   * The backend only allows whitelisted properties to be changed.
   *
   * If the update is successful, the list of users will be updated with the changes to that user.
   * @param userId cognitoId
   * @param user
   */
  const updateUser = async (userId: string, user: Partial<ClientUserData>) => {
    setLoading(true);
    const path = `/client-user-data/${userId}`;
    try {
      const response = await fetch(path, {
        method: "PATCH",
        body: JSON.stringify(user),
      });
      const res: ClientUserData = await response.json();
      if (response.ok) {
        if (users == null) {
          setUsers([res]);
        } else {
          const index = users?.findIndex((u) => u.cognitoId === res.cognitoId);
          if (index == null) {
            setUsers([...users, res]);
          } else {
            setUsers([
              ...users.slice(0, index),
              res,
              ...users.slice(index + 1),
            ]);
          }
        }
        setError(undefined);
      } else {
        console.log(`Error: ${response.statusText}`);
        setError(`Error updating ClientUserData`);
      }
    } catch (e) {
      console.log(`Error: ${e}`);
      setError(`Error updating ClientUserData`);
    }
    setLoaded(true);
  };

  return { getUsers, updateUser, loading, loaded, users, error };
};

interface ClientUserDataResponse {
  clientUserData: ClientUserData[];
}
export interface ClientUserData {
  cognitoId: string;
  user: UserData;
  settings?: {
    allowMultipleBankAccounts: boolean;
  };
}
