import {
  ContentHeroSpace,
  InvestStatsNumbers,
  InvestStatsText,
  InvestStatsTitle,
  Container,
} from "../../../styles/adds";

const InvestStatsSection = () => {
  return (
    <section
      className="container-fluid pt-5 pb-5"
      style={{ background: "#f4f4f4" }}
    >
      <Container className="container pl-1 pr-1">
        <ContentHeroSpace className="contentHeroSpace hidden-xs"></ContentHeroSpace>
        <div
          className="container-fluid p-0 me-3 pe-4"
          style={{ background: "#f4f4f4", color: "#111111" }}
        >
          <div className="row align-items-start justify-content-start">
            <div className="col-sm-12">
              <div
                className="mt-1"
                style={{
                  backgroundColor: "#51976f",
                  width: "100%",
                  height: "4px",
                }}
              ></div>
              <InvestStatsTitle className="mb-3 invest-stats-title">
                Our Stats
              </InvestStatsTitle>
              <InvestStatsText
                className="mb-3 invest-stats-text"
                style={{ width: "100%" }}
              >
                Norhart is committed to changing the construction industry,
                which has stayed the same for decades.
              </InvestStatsText>

              <div className="container ms-0 p-0">
                <div className="row">
                  <div className="col-sm-2">
                    <InvestStatsNumbers>
                      <InvestStatsNumbers>1K</InvestStatsNumbers>
                      <InvestStatsText>Homes Delivered</InvestStatsText>
                    </InvestStatsNumbers>
                  </div>
                  <div className="col-sm-2">
                    <InvestStatsNumbers>
                      <InvestStatsNumbers>13</InvestStatsNumbers>
                      <InvestStatsText>Properties Developed</InvestStatsText>
                    </InvestStatsNumbers>
                  </div>
                  <div className="col-sm-2">
                    <InvestStatsNumbers>
                      <InvestStatsNumbers>$235M</InvestStatsNumbers>
                      <InvestStatsText>Assets Created</InvestStatsText>
                    </InvestStatsNumbers>
                  </div>
                  <div className="col-sm-2">
                    <InvestStatsNumbers>
                      <InvestStatsNumbers>$3M+</InvestStatsNumbers>
                      <InvestStatsText>Capital Raised</InvestStatsText>
                    </InvestStatsNumbers>
                  </div>
                  <div className="col-sm-2">
                    <InvestStatsNumbers>
                      <InvestStatsNumbers>200+</InvestStatsNumbers>
                      <InvestStatsText>Satisfied Investors</InvestStatsText>
                    </InvestStatsNumbers>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default InvestStatsSection;
