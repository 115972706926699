import { useCallback } from "react";
import { CacheKeys } from "../types";
import { User } from "../utils";
import { useFetch } from "./useFetch";

export const useUserSettings = () => {
  const fetchFunction = useCallback(async () => {
    const response = await User.getUserSettings();
    return response;
  }, []);

  const { data, loading, refetchData } = useFetch({
    cacheKey: CacheKeys.userData,
    fetchFunction,
  });

  return {
    loading,
    data,
    refetchData,
  };
};
