import MikeKaeding from "../../../assets/images/landing/leadership/mike-kaeding-leadership.png";
import TimLibertini from "../../../assets/images/landing/leadership/tim-libertini-leadership.png";
import MarieDickover from "../../../assets/images/landing/leadership/marie-dickover-leadership.png";
import CarouselComponent from "../components/CarouselComponent";

const StaffSection = () => {
  return (
    <CarouselComponent
      items={[
        {
          name: "Mike Kaeding",
          title: "CHIEF EXECUTIVE OFFICER",
          image: MikeKaeding,
          buttonName: "Connect Today",
          buttonUrl: "https://www.linkedin.com/in/mikekaeding/",
        },
        {
          name: "Tim Libertini",
          title: "CHIEF FINANCIAL OFFICER",
          image: TimLibertini,
          buttonName: "Connect Today",
          buttonUrl: "https://www.linkedin.com/in/timothy-libertini-81701744/",
        },
        {
          name: "Marie Dickover",
          title: "CHIEF DEVELOPMENT OFFICER",
          image: MarieDickover,
          buttonName: "Connect Today",
          buttonUrl: "https://www.linkedin.com/in/marie-dickover-178095225/",
        },
      ]}
    />
  );
};

export default StaffSection;
