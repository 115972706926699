import { FunctionComponent, useState } from "react";
import { Button, Container, Table } from "react-bootstrap";
import { ClientUserData, useClientUserData } from "../hooks/useClientUserData";
import FilterField from "./FilterField";

/**
 * Show a list of all users on an admin page and allow an admin to change settings per user.
 *
 * There is currently no paging, so all users are loaded in one API request.
 * The user has to trigger this request manually; so if there are any problems with loading that much data, it will be easier to debug.
 * Sorting and filtering are done in memory in the user's browser.
 * Right now the only setting that can be changed is allowMultipleBankAccounts, but other settings could be added easily.
 */
const AdminClientUserDataTable: FunctionComponent = () => {
  const { getUsers, updateUser, loading, loaded, users } = useClientUserData();
  //filters
  const [givenNameFilter, setGivenNameFilter] = useState("");
  const [familyNameFilter, setFamilyNameFilter] = useState("");
  const [emailAddressFilter, setEmailAddressFilter] = useState("");
  const [allowFilter, setAllowFilter] = useState("");
  let filteredUsers = users;
  filteredUsers = filterProperty(
    filteredUsers,
    (u) => u.user.name?.given_name,
    givenNameFilter
  );
  filteredUsers = filterProperty(
    filteredUsers,
    (u) => u.user.name?.family_name,
    familyNameFilter
  );
  filteredUsers = filterProperty(
    filteredUsers,
    (u) => u.user.email_address,
    emailAddressFilter
  );
  filteredUsers = filterProperty(
    filteredUsers,
    (u) => (u.settings?.allowMultipleBankAccounts ? "True" : "False"),
    allowFilter
  );
  //sorting
  const [sortProperty, setSortProperty] = useState("family_name");
  const [sortDirection, setSortDirection] = useState<"ASC" | "DESC">("ASC");
  const sortBy = (newSortProperty: string) => {
    if (newSortProperty === sortProperty) {
      setSortDirection(sortDirection === "ASC" ? "DESC" : "ASC");
    } else {
      setSortProperty(newSortProperty);
      setSortDirection("ASC");
    }
  };
  filteredUsers = sort(filteredUsers, sortProperty, sortDirection);
  //when the user clicks a button in the row
  const toggleMultipleBankAccountsAllowed = (user: ClientUserData) => {
    updateUser(user.cognitoId, {
      settings: {
        allowMultipleBankAccounts: !user.settings?.allowMultipleBankAccounts,
      },
    });
  };
  //
  return (
    <Container style={{ minHeight: "50vh" }}>
      <h4>User Settings</h4>
      <Table>
        <thead>
          <tr>
            <th onClick={() => sortBy("given_name")}>First Name</th>
            <th onClick={() => sortBy("family_name")}>Last Name</th>
            <th onClick={() => sortBy("email_address")}>Email</th>
            <th onClick={() => sortBy("allowMultipleBankAccounts")}>
              Multiple Bank Accounts Allowed
            </th>
          </tr>
          <tr>
            <td>
              <FilterField
                filter={givenNameFilter}
                setFilter={setGivenNameFilter}
                placeholder="Filter"
              />
            </td>
            <td>
              <FilterField
                filter={familyNameFilter}
                setFilter={setFamilyNameFilter}
                placeholder="Filter"
              />
            </td>
            <td>
              <FilterField
                filter={emailAddressFilter}
                setFilter={setEmailAddressFilter}
                placeholder="Filter"
              />
            </td>
            <td>
              <FilterField
                filter={allowFilter}
                setFilter={setAllowFilter}
                placeholder="Filter"
              />
            </td>
          </tr>
        </thead>
        <tbody>
          {filteredUsers?.map((u) => (
            <tr>
              <td>{u.user?.name?.given_name}</td>
              <td>{u.user?.name?.family_name}</td>
              <td>{u.user?.email_address}</td>
              <td>
                <span className="me-4">
                  {u.settings?.allowMultipleBankAccounts ? "True" : "False"}
                </span>
                <Button
                  size="sm"
                  variant="outline-primary"
                  onClick={() => toggleMultipleBankAccountsAllowed(u)}
                >
                  {u.settings?.allowMultipleBankAccounts ? "Disallow" : "Allow"}
                </Button>
              </td>
            </tr>
          ))}
          {!loaded && (
            <tr>
              <td colSpan={10} className="text-center">
                <Button
                  variant="outline-primary"
                  size="sm"
                  disabled={loading}
                  onClick={() => {
                    getUsers();
                  }}
                >
                  {!loading ? "Load Users" : "Loading..."}
                </Button>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
};
export default AdminClientUserDataTable;

const filterProperty = <T,>(
  array: T[] | undefined,
  getter: (v: T) => string | undefined,
  filter: string
) => {
  const filters = filter?.toLowerCase().split(" ");
  for (const filter of filters) {
    array = array?.filter((item: any) =>
      (getter(item) || "").toLowerCase().includes(filter)
    );
  }
  return array;
};

const sort = (
  users: ClientUserData[] | undefined,
  sortProperty: string,
  sortDirection: "ASC" | "DESC"
) => {
  users = users?.sort((a: any, b: any) => {
    if (sortProperty === "given_name") {
      a = a.user?.name?.given_name;
      b = b.user?.name?.given_name;
    } else if (sortProperty === "family_name") {
      a = a.user?.name?.family_name;
      b = b.user?.name?.family_name;
    } else if (sortProperty === "email_address") {
      a = a.user?.email_address;
      b = b.user?.email_address;
    } else if (sortProperty === "allowMultipleBankAccounts") {
      a = a.settings?.allowMultipleBankAccounts;
      b = b.settings?.allowMultipleBankAccounts;
    }
    return a === b ? 0 : a < b ? -1 : 1;
  });
  if (sortDirection === "DESC") users = users?.reverse();
  return users;
};
