import { FunctionComponent } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";

interface IFilterField {
  filter: string;
  setFilter: (v: string) => void;
  placeholder: string;
}
/**
 * A textfield meant to be used in the header of a table to filter for that column.
 * Actual filtering logic has to be implemented in the component that uses this field.
 */
const FilterField: FunctionComponent<IFilterField> = ({
  filter,
  setFilter,
  placeholder,
}) => {
  return (
    <InputGroup>
      <Form.Control
        type="text"
        size="sm"
        placeholder={placeholder}
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
      />
      {filter && (
        <Button
          variant="outline-secondary"
          size="sm"
          onClick={() => setFilter("")}
        >
          Clear
        </Button>
      )}
    </InputGroup>
  );
};
export default FilterField;
